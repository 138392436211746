import React, { Component } from "react"
import { StaticImage } from "gatsby-plugin-image"

export default class App extends Component {
  render() {
    return (
      <>
        <StaticImage
          src="../assets/images/square.png"
          alt="contact"
          className="waveSquare"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
      </>
    )
  }
}

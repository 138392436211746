import React, { useRef, useEffect } from "react"
import Title from "../components/Title"
import { GrMailOption } from "react-icons/Gr"
import SEO from "../components/Seo"
import styled from "styled-components"
import SketchWaveSquareComponent from "../components/SketchWaveSquareComponent"
const WaveSquare = () => {
  return (
    <>
      <SEO title="Kentaro Takahira" />
      <div style={{ backgroundColor: "#6d6c6c" }}>
        <div className="cakes-art">
          <div className="arts-center">
            {/* <div className="sketchContainer"> */}
            <SketchWaveSquareComponent></SketchWaveSquareComponent>
            {/* </div> */}
          </div>
          <div className="sketchDescription">
            <h2 className="sketchTitle" style={{ textAlign: "center" }}>
              Wave Square
            </h2>
            {/* <p className="sketchDetails">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Repudiandae suscipit unde, minima ipsa officia soluta dolor
                  illo nemo optio odio quis, voluptatem vel aliquid itaque?
                  Expedita quae quas, provident doloremque tenetur quidem beatae
                  voluptatum veritatis non dignissimos. Voluptatem ipsum,
                  eligendi possimus obcaecati, maxime eum minima mollitia
                  voluptatibus, doloribus consectetur repellendus. Quia
                  reiciendis impedit vitae numquam libero, placeat id temporibus
                  possimus in dolores, dolor, facere similique. Recusandae, illo
                  soluta! Mollitia maxime, at perspiciatis consectetur vero
                  possimus. Odio praesentium culpa pariatur laboriosam?
                </p> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default WaveSquare
